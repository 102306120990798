import DesktopWrapper from "components/Layout/DesktopWrapper";
import TopBar from "components/Layout/TopBar";
import React from "react";

function AppWrapper({ children }) {
  return (
    <div className="h-screen w-screen overflow-hidden">
      <TopBar />
      <div className="lg:hidden max-h-[100vh] overflow-y-auto mt-11 app-bg">
        {children}
      </div>
      <DesktopWrapper>
        <div className="mt-0 py-0">{children}</div>
      </DesktopWrapper>
    </div>
  );
}

export default AppWrapper;
