import EpicCard from "./EpicCard";
import GoalCard from "./GoalCard";
import MemberCard from "./MemberCard";
import StoryCard from "./StoryCard";
import TaskCard from "./TaskCard";

function EntityComponent({ entity, id }) {
  switch (entity) {
    case "goal":
      return <GoalCard id={id} />;
    case "task":
      return <TaskCard id={id} />;
    case "epic":
      return <EpicCard id={id} />;
    case "story":
      return <StoryCard id={id} />;
    case "member":
      return <MemberCard id={id} />;
    default:
  }
}

export default EntityComponent;
