export function removeStrings(startStr, endStr, str) {
  let result = str;
  let startIndex = result.indexOf(startStr);

  while (startIndex !== -1) {
    const endIndex = result.indexOf(endStr, startIndex + startStr.length);

    if (endIndex !== -1) {
      const substringToRemove = result.substring(
        startIndex,
        endIndex + endStr.length
      );
      result = result.replace(substringToRemove, "");
    }

    startIndex = result.indexOf(startStr, startIndex + 1);
  }

  return result;
}
