import React, { useState, useEffect } from "react";
import OtpInput from "otp-input-react";

// APIs
import firebase from "config/firebase.js";
import {
  loginWithFirebaseAuthToken,
  verifyPhoneNumberForOTP,
} from "config/APIs/auth";

// Redux
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";
import { userUpdate } from "redux/user";

const buttonStates = {
  init: {
    text: "Submit",
    disabled: false,
  },
  verifyNumber: {
    text: "Verifying Number",
    disabled: true,
  },
  sendOtp: {
    text: "Sending OTP",
    disabled: true,
  },
  askOtp: {
    text: "Submit OTP",
    disabled: false,
  },
  verifyOtp: {
    text: "Verifying OTP",
    disabled: true,
  },
  successOtp: {
    text: "Verified, you'll be redirected shortly",
    disabled: false,
  },
};

function SignIn() {
  const dispatch = useDispatch();

  document.documentElement.classList.remove("nav-open");

  useEffect(() => {
    document.body.classList.add("register-page");
    return function cleanup() {
      document.body.classList.remove("register-page");
    };
  });

  const [phoneNo, setPhoneNo] = useState("");
  const [otp, setOTP] = useState("");
  const auth = firebase.auth();
  const [counter, setCounter] = useState(120);
  const [count, setCount] = useState(0);
  const [option, setOption] = useState("phone");
  const [buttonState, setButtonState] = useState(buttonStates["init"]);
  function _handleTextFieldChange(e) {
    if (!isNaN(e.target.value) && e.target.value.toString().length <= 10) {
      setPhoneNo(e.target.value);
      setPhoneNo(e.target.value);
    }
  }

  const handleClick = () => {
    setButtonState(buttonStates["verifyNumber"]);
    //First check whether phone is registered or not
    console.info("Checking number");
    verifyPhoneNumberForOTP(phoneNo)
      .then((res) => {
        console.info("Number found");
        if (res.status == "200" && res.data.success) {
          console.info("Status success");
          setButtonState(buttonStates["sendOtp"]);
          window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
            "send-otp-button",
            {
              size: "invisible",
            }
          );
          console.info("Recaptcha verified");
          const appVerifier = window.recaptchaVerifier;
          console.info("Sending firebase the number");
          firebase
            .auth()
            .signInWithPhoneNumber("+91" + phoneNo, appVerifier)
            .then(function (confirmationResult) {
              console.info("Firebase sign in success");
              setButtonState(buttonStates["askOtp"]);
              setOption("otp");
              window.confirmationResult = confirmationResult;
              console.info("Set confirmation");
              setCounter(120);
              console.log(confirmationResult);
            });
        }
      })
      .catch((e) => {
        console.info("Err in auth number", e, "\n", e?.response);
        setButtonState(buttonStates["init"]);
        dispatch(
          showToast({
            message: e?.response?.data?.message,
            type: "error",
          })
        );
      });
  };

  function otpSubmit() {
    setButtonState(buttonStates["verifyOtp"]);
    console.info("Submitting otp");
    window.confirmationResult
      .confirm(otp)
      .then((result) => {
        console.info("OTP matched");

        setButtonState(buttonStates["successOtp"]);
        dispatch(
          showToast({
            message: "OTP Login Successfull!",
          })
        );

        const token = result.user.getIdToken().then((res) => {
          const body = {
            idToken: res,
          };
          loginWithFirebaseAuthToken(res)
            .then((res) => {
              const { admin: cred, token } = res.data.data;
              cred["role"] = "admin";
              cred["loginType"] = "phone";
              dispatch(
                userUpdate({
                  user: cred,
                  token: token,
                })
              );
              localStorage.setItem("role", "admin");
              localStorage.setItem("login_type", "phone");
              window.location.href = window.location.origin + "/";
            })
            .catch((e) => {
              console.log(e);
            });
        });
      })
      .catch((error) => {
        setButtonState(buttonStates["init"]);
        setOption("phone");
        dispatch(
          showToast({
            message: "OTP Login Unsuccessfull!",
            type: "error",
          })
        );
      });
  }

  return (
    <section className="otp-login-container min-h-screen max-h-screen flex items-stretch text-white indigo-bg relative">
      <div className="w-full flex items-start mt-10vh md:items-center justify-center mx-8 md:mx-0 md:-mt-32 md:px-16 px-0 z-0">
        {/* <div className="absolute z-10 items-center pb-8 pt-16 px-8 chronos-cube-absolute">
          <img
            className="animated-cube mx-auto login-cube"
            src="/assets/images/loaders/chronoscube.gif"
            alt="logo"
          />
        </div> */}
        <div className="w-full z-20">
          {/* <h1 className="my-6 hidden lg:block">
            <p className="poppins font-medium sm:text-4xl text-3xl mt-20 sm:mt-0 text-center font-indigo-300">
              work.<span className="rounded-full font-indigo-400">caarya</span>
            </p>
          </h1> */}
          <img
            className="chronos-login-logo block mx-auto"
            src="/assets/caaryaLogos/text_logo.png"
            alt="logo"
          />

          <div>
            {option === "phone" ? (
              <form
                className="react-form glass-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleClick();
                }}
              >
                <h1 className="text-xl text-indigo-200 font-karla font-medium mb-6 border-chronos border-b pb-2">
                  connect to chronos
                </h1>
                <fieldset className="form-group">
                  <label className="text-indigo-100 text-xs font-bold mb-2.5">
                    Phone Number
                  </label>
                  <input
                    id="formName"
                    className="form-input p-3 font-lato mt-1.5"
                    type="phone"
                    required
                    maxLength={10}
                    pattern="^[0-9]{10}$"
                    onChange={(e) => {
                      e.target.value = e.target.value
                        .trimStart()
                        .replace(/[^0-9]/gi, "");

                      _handleTextFieldChange(e);
                    }}
                  />
                </fieldset>
                <div className="form-group mt-2">
                  <div className=" cursor-pointer">
                    <button
                      id="send-otp-button"
                      type="button"
                      className="btn font-lato bg-indigo-200 text-primary-indigo-650 text-xs mt-4 font-bold capitalize"
                      onClick={handleClick}
                      disabled={buttonState.disabled}
                    >
                      {buttonState.text}
                    </button>
                  </div>
                </div>
              </form>
            ) : (
              <div>
                <form
                  className="react-form glass-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    otpSubmit();
                  }}
                >
                  <h1 className="login-caption font-bold indigo-dark font-lato mb-6">
                    Enter the OTP sent to your phone
                  </h1>
                  <fieldset className="form-group">
                    <OtpInput
                      otpType="number"
                      value={otp}
                      onChange={(value) => {
                        if (value.toString().length == 6) {
                        }
                        setOTP(value);
                      }}
                      OTPLength={6}
                      inputStyles={{
                        width: "32px",
                        height: "35px",
                        border: "none",
                        backgroundColor: "#fff",
                        borderRadius: "4px",
                        padding: "6px",
                        marginRight: "8px",
                      }}
                      separator={<span style={{ width: "5px" }}></span>}
                    />
                  </fieldset>
                  <div className="form-group">
                    <div className=" cursor-pointer">
                      <button
                        id="send-otp-button"
                        type="button"
                        className="btn font-lato bg-indigo-200 text-indigo-900 text-xs mt-4 font-bold capitalize"
                        placeholder=""
                        onClick={() => otpSubmit()}
                        disabled={buttonState.disabled}
                      >
                        {buttonState.text}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default SignIn;
