import React, { useEffect, useRef, useState } from "react";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Check, Pencil } from "@phosphor-icons/react";
import { AnimatePresence, motion } from "framer-motion";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { useSelector } from "react-redux";
import { getQueryResponse } from "config/APIs/query";
import EntityComponent from "components/Query";
import Answer from "components/Query/Answer";
import StatCard from "components/Query/StatCard";
import { HiMiniMicrophone } from "react-icons/hi2";
import { variants } from "helpers/constants/query";
import Example from "components/Query/Example";
import useChatScroll from "helpers/utils/chat";
import { BiMicrophoneOff } from "react-icons/bi";

const QueryPage = () => {
  const [isWriting, setIsWriting] = useState(false);
  const [startingSpeech, setStartingSpeech] = useState(false);
  const [editQuery, setEditQuery] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [newQuery, setNewQuery] = useState("");
  const [queryList, setQueryList] = useState([]);
  const [startCount, setStartCount] = useState(0);
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [isListening, setIsListening] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const user = useSelector((state) => state?.user?.user);
  const ref = useChatScroll(queryList);
  const microphoneRef = useRef(null);

  const speechHandler = (msg, start) => {
    const speech = new SpeechSynthesisUtterance();
    speech.text = msg;
    speech.volume = 0.8;
    speech.rate = 0.8;
    speech.pitch = 0.9;

    console.log(msg);
    window.speechSynthesis.speak(speech);
  };

  const getStarted = () => {
    if (!startingSpeech) {
      let text = `Hi ${user?.first_name}! How can I help you today?`;

      const speech = new SpeechSynthesisUtterance();
      speech.text = text;
      speech.volume = 0.8;
      speech.rate = 0.8;
      speech.pitch = 0.9;

      console.log("start", text);
      console.log(startingSpeech);
      window.speechSynthesis.speak(speech);
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      console.log(startingSpeech, "data");
      setStartingSpeech(true);
      setTimeout(() => {
        getStarted();
      }, 1000);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (window.speechSynthesis.speaking && !isSpeaking) {
      setIsSpeaking(true);
    } else {
      setIsSpeaking(false);
    }
    return () => {};
  }, [window.speechSynthesis.speaking]);

  const handleListing = () => {
    setIsListening(true);
    microphoneRef.current.classList.add("speaking");
    SpeechRecognition.startListening({
      continuous: true,
    });
  };
  const stopHandle = () => {
    microphoneRef.current.classList.remove("speaking");
    SpeechRecognition.stopListening();
    setNewQuery(transcript);
    setEditQuery(false);
    setIsListening(false);
  };
  const handleReset = () => {
    stopHandle();
    resetTranscript();
  };

  useEffect(() => {
    setNewQuery(transcript);
  }, [transcript]);

  useEffect(() => {
    if (!isListening && newQuery !== "") {
      getResponse();
    }
  }, [isListening]);

  const getResponse = async () => {
    setFetching(true);
    let list = [...queryList];
    list.push({
      question: newQuery,
    });
    setQueryList(list);
    setTimeout(async () => {
      try {
        let res = await getQueryResponse({ message: newQuery });
        let message = res?.data?.data?.text;
        let speech = res?.data?.data?.speech;
        let list = [...queryList];
        list.push({
          question: newQuery,
          answer: message?.includes("[|c|") ? null : message,
          stats: message?.includes("[|c|") ? message : null,
          parameters: res?.data?.data?.parameters?.fields,
        });
        console.log(list, queryList);

        // list[list?.length - 1] = {
        //   question: newQuery,
        //   answer: message?.includes("[|c|") ? null : message,
        //   stats: message?.includes("[|c|") ? message : null,
        //   parameters: res?.data?.data?.parameters?.fields,
        // };
        console.log(list);
        setQueryList(list);
        speechHandler(speech);
        setNewQuery("");
        setIsWriting(true);
        handleReset();
      } catch (e) {
        console.log(e);
      } finally {
        setFetching(false);
      }
    }, 2000);
  };

  const showMoksha = (q, ind) => {
    console.log(queryList, ind);
    return fetching
      ? fetching
      : q?.question
      ? q?.answer || q?.stats || q?.entity
      : queryList[ind - 1]?.question &&
        !queryList[ind - 1]?.answer &&
        !queryList[ind - 1]?.stats &&
        !queryList[ind - 1]?.entity &&
        (q?.answer || q?.stats || q?.entity);
  };

  return (
    <div className="h-screen overflow-y-auto">
      <AnimatePresence>
        <div className="pt-5 relative h-screen max-h-[99vh]">
          <div className="flex flex-row items-stretch justify-end">
            <div className="w-full border-primary-blue-100 border-b-4 py-4 px-7.5 border-opacity-60 border-l-0 border-r-0 border-t-0">
              <div className="flex flex-row text-white opacity-90 items-center justify-between">
                <TextareaAutosize
                  disabled={!isWriting}
                  className="p-0 border-0 bg-transparent text-base tracking-wide focus:outline-0 focus:ring-0 focus:border-transparent focus:border-0 w-full font-lato text-white placeholder-white"
                  placeholder="Start typing to enter a query.."
                  value={newQuery}
                  onChange={(event) => {
                    setNewQuery(event.target.value);
                    setIsWriting(true);
                  }}
                  onKeyDown={(event) => {
                    if (event.key == "Enter" || event.which == 13) {
                      setEditQuery(false);
                      setIsWriting(false);
                      getResponse();
                    }
                  }}
                />
                {queryList?.length > 0 && newQuery?.length > 0 && (
                  <div className="w-6 relative">
                    <Check
                      onClick={() => {
                        setEditQuery(false);
                        getResponse();
                      }}
                      size={16}
                      className="text-white"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>{" "}
          <div
            ref={ref}
            className="max-h-[70vh] overflow-y-auto w-full flex flex-col items-stretch px-4 mt-4"
          >
            {queryList?.map((q, index) => {
              return (
                <>
                  {q?.question && (
                    <div className="flex flex-row items-stretch justify-end w-full mb-8">
                      <div className=" w-full max-w-[80%] query-box wrapper p-[1px]">
                        <div className="bg-primary-blue-900 bg-opacity-80 w-full rounded-lg">
                          <div className="text-right flex flex-row items-stretch justify-end query-box p-4 placeholder:text-white w-full focus:outline-none focus:ring-transparent focus:border focus:border-white">
                            <p>{q?.question}</p>
                            <div className="w-6 relative">
                              <Pencil
                                size={16}
                                className="text-white absolute top-0 right-0"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="space-y-2.5 mb-8">
                    {showMoksha(q, index) && (
                      <div className="flex flex-row items-stretch justify-start w-full">
                        <img
                          src="/assets/images/defaultUser.png"
                          className="w-11 h-11 rounded-full object-contain"
                        />
                      </div>
                    )}
                    {(q?.answer || fetching) && (
                      <Answer
                        current={index == queryList?.length - 1}
                        index={index}
                        answer={q?.answer}
                        parameters={q?.parameters}
                        queryList={queryList}
                        setQueryList={setQueryList}
                        fetching={q?.question && !q?.answer}
                      />
                    )}
                    {q?.stats && <StatCard answer={q?.stats} />}
                    {q?.entity && (
                      <div className="flex flex-row items-stretch justify-start w-full">
                        <EntityComponent
                          entity={q?.entity?.type}
                          id={q?.entity?.id}
                        />
                      </div>
                    )}
                  </div>
                </>
              );
            })}
            {SpeechRecognition.browserSupportsSpeechRecognition() && (
              <motion.div
                animate={queryList?.length > 0 || isWriting ? "closed" : "open"}
                variants={variants}
                className="w-full flex flex-col items-center justify-center absolute -mx-4"
              >
                <div
                  ref={microphoneRef}
                  className="border border-white rounded-full"
                >
                  <div
                    onTouchStart={() => {
                      handleListing();
                    }}
                    onTouchEnd={() => {
                      stopHandle();
                    }}
                    onClick={() => {
                      if (!isSpeaking) {
                        if (isListening) stopHandle();
                        else handleListing();
                      } else {
                        window.speechSynthesis.cancel();
                        setIsSpeaking(false);
                      }
                    }}
                    className={`query-speak ${
                      queryList?.length > 0 || isWriting ? "p-4" : "p-5"
                    } flex flex-row items-center justify-center`}
                  >
                    {isSpeaking ? (
                      <BiMicrophoneOff
                        size={queryList?.length > 0 || isWriting ? 16 : 60}
                      />
                    ) : (
                      <HiMiniMicrophone
                        size={queryList?.length > 0 || isWriting ? 16 : 60}
                      />
                    )}
                  </div>
                </div>

                <motion.p
                  animate={queryList?.length > 0 || isWriting ? "hide" : "show"}
                  variants={variants}
                  className="text-2xs text-center text-white font-karla mt-2.5"
                >
                  Tap & Hold to Speak
                </motion.p>
              </motion.div>
            )}{" "}
            {queryList?.length > 0 || isWriting ? null : (
              <div className="space-y-5 text-center flex flex-col items-center w-full -mt-2">
                <p className="text-xs text-center text-white font-karla mt-2.5">
                  OR
                </p>{" "}
                <p className="text-lg font-lato opacity-80 text-white font-semibold max-w-[75vw] mx-auto capitalize leading-7">
                  Ask me something by speaking into the mic
                </p>{" "}
                <p className="text-sm font-lato opacity-80 text-white font-semibold">
                  Examples:
                </p>
                <Example example="Goals for the week" />
                <Example example="Who are the members of Caarya" />
                <Example example="Releases for the month" />
              </div>
            )}
          </div>{" "}
        </div>
      </AnimatePresence>
      {/* {isVisible && (
        <div
          className={`fixed right-6 md:right-8 lg:right-32 bottom-16 md:bottom-12 block transition-all`}
        >
          <div
            className="scroll-to-top-arrow rounded-full h-9 w-9 p-3 flex items-center justify-center cursor-pointer"
            onClick={() => {
              handleScroll();
            }}
          >
            <div className="flex flex-col items-center">
              <ArrowUpIcon className=" h-6 w-6 text-white" />
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default QueryPage;
