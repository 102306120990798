export const variants = {
  open: {
    top: "70%",
    height: "116px",
    scale: "1.4",
  },
  closed: {
    bottom: "120px",
    height: "32px",
    top: "80%",
    scale: "1.0",
    transition: {
      duration: 0.8,
      delay: 0.2,
    },
  },
  show: {
    opacity: 1,
    transition: {
      duration: 0.2,
    },
  },
  hide: {
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
  expand: {
    width: "100%",
    transition: {
      duration: 0.2,
    },
  },
  contract: {
    width: "80%",
    transition: {
      duration: 0.2,
    },
  },
};
