import moment from "moment";
import { useEffect, useState } from "react";
import { Transition, Disclosure } from "@headlessui/react";
import { CheckFat, Target } from "@phosphor-icons/react";
import { getEpicsById } from "config/APIs/task/epics";

function EpicCard({ id }) {
  const [details, setDetails] = useState();

  useEffect(() => {
    fetchEpic();
  }, [id]);

  const fetchEpic = async () => {
    try {
      let res = await getEpicsById(id);
      setDetails(res?.data?.data);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div className="border border-primary-gray-80 bg-white rounded-lg p-4 w-full max-w-lg space-y-2">
        <div className="flex bg-primary-gray-50 border border-gray-80 rounded px-2 py-0.5 space-x-2 max-w-max flex-row items-center">
          <CheckFat className="w-4 h-4 text-primary-red-medium" />
          <span className="font-semibold font-karla text-2xs text-primary-gray-600">
            Epic
          </span>
        </div>
        <div className="text-primary-gray-600 font-karla text-sm w-full">
          {details?.title}
        </div>
        <div className="text-primary-gray-250 font-lato text-xs w-full">
          <b>Due On : </b>
          <span className="text-xs">
            {moment(details?.end_date).format("ll")}
          </span>
        </div>
        {/* <div className="text-primary-gray-250 font-lato text-xs w-full">
          <b> Owned By : </b>
          <span className="text-xs">
            {details?.owner ? details?.owner?.first_name : "NA"}
          </span>
        </div> */}
        <Disclosure className="w-full space-y-4">
          {({ open }) => (
            <>
              <Disclosure.Button
                disabled
                className="w-full pb-2 border-b border-primary-gray-80"
              >
                <div className="text-primary-gray-400 space-x-0.5 text-xs font-semibold font-lato flex flex-row-reverse items-center justify-between w-full">
                  <div className="flex flex-row  space-x-0.5 items-center">
                    <p>Stories ({details?.stories?.length || 0})</p>
                    {/* <BiChevronUp
                      className={
                        open
                          ? "transform w-6 scale-150"
                          : "-rotate-180 w-6 scale-150"
                      }
                    /> */}
                  </div>
                </div>
              </Disclosure.Button>

              {/* <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel className="w-full space-y-2 px-2">
                  {goal?.tasks?.map((task) => {
                    return (
                      <div className="flex flex-col bg-primary-gray-50 py-2 px-4 rounded-lg space-y-2">
                        <div className="flex flex-row items-center justify-between w-full">
                          <p className="w-10/12 text-primary-gray-600 font-karla text-sm">
                            {task?.title}
                          </p>
                          {task?.priority && (
                            <PriorityDropdown
                              priorityValue={task?.priority}
                              disabled
                            />
                          )}
                        </div>
                        <div className="flex flex-row items-center justify-between w-full">
                          <div className="text-primary-gray-250 font-lato text-xs">
                            <b>Due On :</b>
                            <span className="text-xs">
                              {moment(task?.dueDate).format("ll")}
                            </span>
                          </div>
                          <StatusDropDown status={task?.status} disabled />
                        </div>
                        {canDeleteTask(task) && task?.id != 0 && (
                          <div className="mt-2.5 block">
                            <ChronosButton
                              text="Delete"
                              onClick={() => {
                                setDeleteModal({
                                  type: "task",
                                  task,
                                });
                              }}
                              tertiary
                              red
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                  {addTask ? (
                    <div className="addTaskCard px-6 py-4 rounded-lg space-y-4">
                      <div className="flex flex-row items-center space-x-4">
                        <label className="font-semibold text-sm text-primary-gray-600 font-lato">
                          Task:
                        </label>
                        <input
                          value={details?.title}
                          onChange={(e) => {
                            setDetails({
                              ...details,
                              title: e.target.value,
                            });
                          }}
                          type="text"
                          placeholder="Add task details.."
                          className="border-b w-full border-primary-gray-250 rounded-t-lg border-t-0 border-l-0 border-r-0 bg-white text-sm text-primary-gray-600 px-4 py-2"
                        />
                      </div>
                      <div className="flex flex-row items-center space-x-4">
                        <label className="font-semibold text-sm text-primary-gray-600 font-lato">
                          Due On:{" "}
                        </label>
                        <div className="border-b w-full border-primary-gray-250 rounded-t-lg border-t-0 border-l-0 border-r-0 bg-white text-sm text-primary-gray-600 max-w-max">
                          <DateInput
                            noBorder
                            date={details?.dueDate}
                            onChange={(val) => {
                              setDetails({
                                ...details,
                                dueDate: val,
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="flex justify-between mt-4">
                        <ChronosButton
                          underline
                          tertiary
                          text="Cancel"
                          onClick={() => {
                            setAddTask(false);
                            setDetails({});
                          }}
                        />
                        <ChronosButton
                          loader={creating}
                          primary
                          orange
                          reverseIcon
                          icon={<Plus size={12} className="mr-2" />}
                          text="Add Now"
                          onClick={() => {
                            addTasks();
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="flex justify-end">
                      <ChronosButton
                        underline
                        tertiary
                        reverseIcon
                        icon={<Plus size={12} className="mr-2" />}
                        text="Add Task"
                        onClick={() => {
                          setAddTask(true);
                          setDetails({
                            projectId: item?.id,
                            goalId: goal?.id,
                            ownerId: user?.id,
                            status: "NotStarted",
                          });
                        }}
                      />
                    </div>
                  )}
                </Disclosure.Panel>
              </Transition> */}
            </>
          )}
        </Disclosure>
      </div>
    </>
  );
}

export default EpicCard;
