import React from "react";

function Example({ example }) {
  return (
    <div className="flex flex-row items-stretch justify-center w-full mb-8">
      <div className="w-full max-w-[80%] query-box wrapper p-[1px]">
        <div className="bg-primary-blue-900 w-full rounded-lg">
          <div className="text-right flex flex-row items-stretch justify-center p-4 placeholder:text-white w-full focus:outline-none focus:ring-transparent focus:border focus:border-white">
            <p>{example}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Example;
