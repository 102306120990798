import { User } from "@phosphor-icons/react";
import { getAUser } from "config/APIs/users";
import { useEffect, useState } from "react";

function MemberCard({ id }) {
  const [details, setDetails] = useState();

  useEffect(() => {
    fetchMember();
  }, [id]);

  const fetchMember = async () => {
    try {
      let res = await getAUser(id);
      setDetails(res?.data?.data);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="border border-primary-gray-80 bg-white rounded-lg p-4 w-full max-w-sm space-y-4 flex flex-row items-start space-x-4">
      {details?.image ? (
        <img
          src={details?.image?.url}
          className="rounded-full w-10 h-10 object-contain "
        />
      ) : (
        <div
          src={details?.image?.url}
          className="rounded-full w-10 h-10 object-contain bg-primary-gray-150 flex flex-row items-center justify-center"
        >
          <User size={32} />
        </div>
      )}
      <div className="flex flex-col items-start space-y-0.5">
        <div className="text-primary-blue-400 font-karla text-base font-medium w-full">
          {details?.title}
        </div>
        <div className="text-primary-gray-800 font-inter text-xs font-thin w-full">
          {details?.designation?.role?.role_name}
        </div>
        <div className="text-primary-gray-800 font-inter text-xs font-thin w-full">
          {details?.designation?.designation_name}
        </div>
      </div>
    </div>
  );
}

export default MemberCard;
