import { removeStrings } from "helpers/utils/query";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { variants } from "helpers/constants/query";

function Answer({
  answer,
  parameters,
  queryList,
  setQueryList,
  index,
  fetching,
}) {
  const [showAns, setShowAns] = useState(!fetching);
  const [viewMore, setViewMore] = useState(1);
  const getAnswer = (answer, parameters) => {
    let ans = answer.substr(0, answer.indexOf(":") + 1);
    let list = answer.substr(answer.indexOf(":") + 1);

    let ele = answer.indexOf(":") > 0 ? [<br />] : [];
    if (list) {
      list = list.split("\n");
      let totalPages = parseInt(list?.length / 10);

      list?.slice(0, viewMore * 10)?.map((e) => {
        if (e?.includes("|i|")) {
          ele.push(
            <i onClick={() => {}}>
              <br />
              {e?.replaceAll("[|i|", "")?.replaceAll("|i|]", "")}
            </i>
          );
        } else {
          ele.push(
            <p
              className="cursor-pointer"
              onClick={() => {
                let l = [...queryList];
                let obj = {
                  entity: {
                    type:
                      parameters?.entity[parameters?.entity?.kind] || "member",
                    id: e
                      .substr(e.indexOf("[|id|") + 5, e.indexOf("]") + 1)
                      .replaceAll("|id|]", ""),
                  },
                };
                console.log(obj);
                l.push(obj);
                setQueryList(l);
              }}
            >
              {removeStrings("[", "]", e)}
            </p>
          );
        }
      });

      if (totalPages > 0 && viewMore <= totalPages) {
        ele.push(
          <i
            className="cursor-pointer"
            onClick={() => {
              if (index == queryList?.length - 1) setViewMore(viewMore + 1);
            }}
          >
            <br />
            View More
          </i>
        );
      }
    }
    return (
      <>
        <p>{ans}</p>

        {ele?.length > 0 &&
          ele?.map((e) => {
            return e;
          })}
      </>
    );
  };

  useEffect(() => {
    if (!fetching) {
      setTimeout(() => {
        setShowAns(true);
      }, 500);
    }
  }, [fetching]);

  return (
    <div
      className={`flex flex-row items-stretch justify-start w-full query-box moksha-wrapper max-w-[80%] p-[1px] ${
        fetching ? "answer-box" : "answer-box-expanded"
      }`}
    >
      <div className="bg-primary-blue-900 bg-opacity-80 w-full rounded-lg">
        <div className="text-left flex flex-col items-start justify-start query-box moksha p-4 placeholder:text-white w-full focus:outline-none focus:ring-transparent focus:border focus:border-white">
          {fetching ? (
            <div className="dot-typing mx-4" />
          ) : answer ? (
            <motion.div
              initial={variants.hide}
              animate={showAns ? "show" : "hide"}
              variants={variants}
            >
              {getAnswer(answer, parameters)}
            </motion.div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Answer;
