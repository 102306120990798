import { removeStrings } from "helpers/utils/query";
import React from "react";

function StatCard({ answer }) {
  const getStat = (answer) => {
    let ele = `<p>${removeStrings("[|c|", "|c|]", answer)
      ?.replaceAll("[|b|", "<b>")
      ?.replaceAll("|b|]", "</b>")}</p>`;

    return <div dangerouslySetInnerHTML={{ __html: ele }}></div>;
  };

  const getCount = (answer) => {
    console.log(answer);
    return answer?.substring(
      answer?.indexOf("[|c|") + 4,
      answer?.indexOf("|c|]")
    );
  };

  return (
    <div className="flex flex-row items-stretch justify-start w-full query-box moksha-wrapper max-w-[80%] p-[1px]">
      <div className="bg-primary-blue-900 bg-opacity-80 w-full rounded-lg">
        <div className="text-left flex flex-col items-start justify-start query-box moksha p-4 placeholder:text-white w-full focus:outline-none focus:ring-transparent focus:border focus:border-white">
          <div className="text-4xl">{getCount(answer)}</div>
          <div className="font-normal">{getStat(answer)}</div>
        </div>
      </div>
    </div>
  );
}

export default StatCard;
