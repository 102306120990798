import { CheckFat } from "@phosphor-icons/react";
import { getStoriesById } from "config/APIs/task/stories";
import { getTask } from "config/APIs/task/task";
import moment from "moment";
import { useEffect, useState } from "react";

function StoryCard({ id }) {
  const [details, setDetails] = useState();

  useEffect(() => {
    fetchStory();
  }, [id]);

  const fetchStory = async () => {
    try {
      let res = await getStoriesById(id);
      setDetails(res?.data?.data);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="border border-primary-gray-80 bg-white rounded-lg p-4 w-full max-w-lg space-y-2">
      <div className="flex bg-primary-gray-50 border border-gray-80 rounded px-2 py-0.5 space-x-2 max-w-max flex-row items-center">
        <CheckFat className="w-4 h-4 text-primary-red-medium" />
        <span className="font-semibold font-karla text-2xs text-primary-gray-600">
          Story
        </span>
      </div>
      <div className="text-primary-gray-600 font-karla text-sm w-full">
        {details?.title}
      </div>
      <div className="text-primary-gray-250 font-lato text-xs w-full">
        <b>Due On : </b>
        <span className="text-xs">
          {moment(details?.end_date).format("ll")}
        </span>
      </div>
      <div className="text-primary-gray-250 font-lato text-xs w-full">
        <b> Owned By : </b>
        <span className="text-xs">
          {details?.owner ? details?.owner?.first_name : "NA"}
        </span>
      </div>
    </div>
  );
}

export default StoryCard;
