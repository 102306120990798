import { CheckFat, User } from "@phosphor-icons/react";
import { getTask } from "config/APIs/task/task";
import moment from "moment";
import { useEffect, useState } from "react";

function TaskCard({ id }) {
  const [details, setDetails] = useState();

  useEffect(() => {
    fetchTask();
  }, [id]);

  const fetchTask = async () => {
    try {
      let res = await getTask(id);
      setDetails(res?.data?.data);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="border border-primary-gray-80 bg-white rounded-lg p-4 w-full max-w-lg space-y-2">
      <div className="flex bg-primary-gray-50 border border-gray-80 rounded px-2 py-0.5 space-x-2 max-w-max flex-row items-center">
        <CheckFat className="w-4 h-4 text-primary-red-medium" />
        <span className="font-semibold font-karla text-2xs text-primary-gray-600">
          Task
        </span>
      </div>
      <div className="text-primary-gray-600 font-karla text-sm w-full">
        {details?.title}
      </div>
      <div className="text-primary-gray-250 font-lato text-xs w-full">
        <b>Due On : </b>
        <span className="text-xs">{moment(details?.dueDate).format("ll")}</span>
      </div>
      <div className="text-primary-gray-250 font-lato text-xs w-full flex flex-row items-center space-x-2.5 pt-3">
        <b> Owned By : </b>
        <span className="text-xs flex flex-row items-center space-x-2.5">
          {details?.owner?.image ? (
            <img
              src={details?.owner?.image?.url}
              className="rounded-full w-6 h-6 object-contain "
            />
          ) : (
            <div
              src={details?.owner?.image?.url}
              className="rounded-full w-6 h-6 object-contain bg-primary-gray-150 flex flex-row items-center justify-center"
            >
              <User size={20} />
            </div>
          )}{" "}
          <p>{details?.owner ? details?.owner?.first_name : "NA"}</p>
        </span>
      </div>
    </div>
  );
}

export default TaskCard;
